<div id="modal-header">
  <h3 nz-title class="bnpp-sans-condensed to-upper-case">
    {{ 'modals.projection-chart-calculation.title' | translate }}
  </h3>
</div>

<div id="modal-content">
  <div id="modal-content__header" nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <img src="./assets/images/calculation-projection-graph.svg" />
  </div>

  <ng-container *ngFor="let block of blocks">
    <div class="legend">
      <div class="legend-header" nz-row nzType="flex" nzAlign="middle" nzJustify="start">
        <span class="legend-header__icon" [ngClass]="block.className"></span>
        <span class="legend-header__title">{{ block.titleKey | translate }}</span>
        <span class="legend-header__subtitle">{{ block.subTitleKey | translate }}</span>
      </div>
      <div class="legend-content" [innerHTML]="block.descriptionKey | translate"></div>
      <ng-container *ngIf="block.disclaimerKey">
        <div class="legend-footer" [innerHTML]="block.disclaimerKey | translate"></div>
      </ng-container>
    </div>
  </ng-container>
</div>

<div id="modal-footer">
  <div nz-row nzType="flex" nzJustify="center" nzAlign="middle">
    <p class="text-align__center">
      {{ 'modals.projection-chart-calculation.disclaimer' | translate }}
    </p>
  </div>
</div>
