import { Injectable } from '@angular/core';
import { ProjectionChartPoint, ProjectionParameters } from '@app/types';
import { InitialInvestmentMatrixDTO } from '@backend/dto';

@Injectable({ providedIn: 'root' })
export class MandateProjectionComputationService {
  public compute(
    matrix: InitialInvestmentMatrixDTO,
    projectionParameters: ProjectionParameters
  ): ProjectionChartPoint[] {
    return new Array(16)
      .fill(null)
      .map((item, index) =>
        this._computeSourcePointData(
          matrix,
          index ? index * 12 - 1 : index,
          projectionParameters.objective || projectionParameters.investedAmount
        )
      );
  }

  private _computeSourcePointData(
    matrix: InitialInvestmentMatrixDTO,
    months: number,
    objective?: number
  ): ProjectionChartPoint {
    const sourcePoint: ProjectionChartPoint = {
      best: matrix?.bestScenario[months] ?? 0,
      likely: matrix?.mostLikelyScenario[months] ?? 0,
      worst: matrix?.worstScenario[months] ?? 0,
      objective
    };

    return {
      ...sourcePoint,
      halfBest: (sourcePoint.likely + sourcePoint.best) / 2,
      halfWorst: (sourcePoint.likely + sourcePoint.worst) / 2
    };
  }
}
