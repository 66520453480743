import { Injectable } from '@angular/core';
import { ProjectionParameters } from '@app/types';
import { InvestmentPlanSimulationCashFlowRequestDTO } from '@backend/dto';
import { getInvestmentPlanSimulationCashFlow } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { MandateProjectionComputationService } from '@services/mandate-projection-computation.service';
import { SimulationCashFlowStoreService } from '@stores';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetInvestmentPlanSimulationCashFlowCommand implements ICommand {
  constructor(
    private readonly _simulationCashFlowStoreService: SimulationCashFlowStoreService,
    private readonly _mandateProjectionComputationService: MandateProjectionComputationService
  ) {}

  public execute(data: { investmentPlanIdentifier: string; projectionParameters: ProjectionParameters }): void {
    const payload: InvestmentPlanSimulationCashFlowRequestDTO = {
      horizon: 15,
      initialCapital: data.projectionParameters.investedAmount,
      recurrenceTextId: data.projectionParameters.recurrenceFrequency.textId,
      recurringAmount: data.projectionParameters.recurrentAmount
    };

    getInvestmentPlanSimulationCashFlow({
      pathParams: { investmentPlanIdentifier: data.investmentPlanIdentifier },
      payload
    })
      .execute()
      .pipe(
        tap((response) => this._simulationCashFlowStoreService.updateMatrix(response)),
        tap((response) =>
          this._simulationCashFlowStoreService.updateProjectionChartPoints(
            this._mandateProjectionComputationService.compute(response, data.projectionParameters)
          )
        ),
        catchError((error) => {
          this._simulationCashFlowStoreService.updateLookingForwardBackendServiceNotWorking({ isProjectionChartPointsNotWorking: true });
          return throwError(error);
        })
      )
      .subscribe();
  }
}
