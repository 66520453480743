import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';

export type BlockItem = {
  className: 'best' | 'neutral' | 'worst';
  titleKey: string;
  subTitleKey: string;
  descriptionKey: string;
  disclaimerKey?: string;
};

@Component({
  selector: 'app-projection-chart-calculation-modal',
  templateUrl: './projection-chart-calculation.modal.html',
  styleUrls: ['./projection-chart-calculation.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectionChartCalculationModal implements OnInit {
  public blocks: BlockItem[];

  constructor(private readonly _nzModalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.blocks = [
      {
        className: 'best',
        titleKey: 'modals.projection-chart-calculation.best.title',
        subTitleKey: 'modals.projection-chart-calculation.best.sub-title',
        descriptionKey: 'modals.projection-chart-calculation.best.description',
        disclaimerKey: 'modals.projection-chart-calculation.best.disclaimer'
      },
      {
        className: 'neutral',
        titleKey: 'modals.projection-chart-calculation.neutral.title',
        subTitleKey: 'modals.projection-chart-calculation.neutral.sub-title',
        descriptionKey: 'modals.projection-chart-calculation.neutral.description'
      },
      {
        className: 'worst',
        titleKey: 'modals.projection-chart-calculation.worst.title',
        subTitleKey: 'modals.projection-chart-calculation.worst.sub-title',
        descriptionKey: 'modals.projection-chart-calculation.worst.description'
      }
    ];
  }

  public onButtonClicked(): void {
    this._nzModalRef.close();
  }
}
